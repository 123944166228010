// TODO monthly plan can be removed when using standard plan

export const planType = {
  ANNUAL: 'ANNUAL',
  STANDARD: 'STANDARD',
  BASIC: 'BASIC',
  TVOD: 'TVOD',
  SIX_MONTH_OFFER: 'BUNDLE_2021_SIX_MONTH'
}

export const links = {
  planSelection: '/signup/plan',
  createAccount: '/signup/account',
  payment: '/signup/payment',
  confirmation: '/signup/confirmation',
  abandon: '/signup/abandon',
  error: '/signup/error',
  terms: '/pages/terms',
  privacy: '/pages/privacy',
  // TODO(AM): Update with actual help desk URL when available
  voucherHelp:
    'https://helpneon.freshdesk.com/support/solutions/articles/64000211109-how-do-i-sign-up-for-neon-',
  myAccountSubscription: '/my-account/subscription'
}

/**
 * State for used in router to identify previous sign up flow state
 */
export const signupSequence = {
  createAccount: 1,
  planSelection: 2,
  abandon: 2,
  payment: 3,
  confirmation: 4
}

/**
 * content for display in subscription modal
 */
export const modalContent = {
  selection: 'selection',
  voucher: 'voucher',
  payment: 'payment',
  paymentMethods: 'paymentMethod',
  confirmation: 'confirmation',
  voucherValidateErrorAfterLogin: 'voucherValidateErrorAfterLogin',
  error: 'error'
}

export const planDetailsForAvod = {
  componentTitle: 'CHOOSE THE WAY YOU PAY',
  types: ['Basic', 'Standard', 'Annual'],
  unlimitedAccess: 'Unlimited access to TV series and movies',
  features: [
    {
      title: 'Screens you can watch on at the same time',
      iconName: 'devices',
      content: ['2 Screens', '2 Screens', '2 Screens']
    },
    {
      title: 'Number of user profiles',
      iconName: 'group',
      content: ['5 profiles', '5 profiles', '5 profiles']
    },
    {
      title: 'Download available',
      iconName: 'download',
      content: [false, true, true]
    },
    {
      title: 'Video quality',
      iconName: 'quality',
      content: ['HD', 'HD', 'HD']
    },
    {
      title: 'Ad options',
      iconName: 'player',
      extraInfoWithTitle: ['Video Ads + Pause Ads*', 'Pause Ads only*', 'Pause Ads only*'],
      subTitle: 'Learn More',
      subTitleLink: '/ads-on-neon'
    },
    {
      title: 'Resolution',
      iconName: 'resolution',
      content: ['1080p', '1080p', '1080p']
    }
  ]
}

const basicPlanPriceForAvod = '12.99'

export const basicPlanDetailsForAvod = {
  id: 'b9f0c585-fbaa-4617-9366-8ddbd23e2ba4',
  sku: 'LBBASICTV',
  type: planType.BASIC,
  badge: 'most flexible plan',
  title: 'Basic',
  label: 'Basic with Ads',
  price: basicPlanPriceForAvod,
  period: 'monthly',
  periodNew: 'per month',
  staticDetail: {
    name: 'Basic with Ads Plan',
    value: `$${basicPlanPriceForAvod}/month`
  },
  offerDetail: {
    name: 'BASIC WITH ADS PLAN',
    desc: `$${basicPlanPriceForAvod} per month, cancel anytime`
  },
  voucherDetail: {
    header: 'This Basic with Ads Plan voucher has been added to your account',
    subHeader: 'What’s included in the Basic with Ads Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'HD video quality', `Includes video ads and pause ads (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Basic with Ads Plan will cost $${basicPlanPriceForAvod} per month after your voucher expires`
  }
}

const standardPlanPriceForAvod = '19.99'

export const standardPlanDetailsForAvod = {
  id: '47164451-2432-4beb-a4c4-9978084cc78f',
  sku: 'LBPREMTV',
  type: planType.STANDARD,
  badge: 'most flexible plan',
  title: 'Standard',
  label: 'Standard',
  price: standardPlanPriceForAvod,
  period: 'monthly',
  periodNew: 'per month',
  features: [
    'Hit shows and blockbuster movies',
    'Binge-watch with no ads',
    'Download & Go selected content',
    'Watch on two screens at once'
  ],
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanPriceForAvod}/month`
  },
  offerDetail: {
    name: 'STANDARD PLAN',
    desc: `$${standardPlanPriceForAvod} per month, cancel anytime`
  },
  voucherDetail: {
    header: 'This Standard Plan voucher has been added to your account',
    subHeader: 'What’s included in the Standard Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Standard Plan will cost $${standardPlanPriceForAvod} per month after your voucher expires`
  }
}

export const legacyPlanDetailsForAvod = {
  id: 'e6721fe1-ae1f-42a5-9423-b6f9503faebd',
  sku: 'LBSTDTV',
  title: 'monthly',
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanPriceForAvod}/month`
  }
}

const annuallyPlanPriceForAvod = '199.99'

export const annuallyPlanDetailsForAvod = {
  id: 'd334eb95-ad36-446f-8807-e497b9a69be4',
  sku: 'NEONPRP12',
  type: planType.ANNUAL,
  badge: 'prepay and save',
  title: 'Annual',
  label: 'Annual',
  content: 'Save over 16% when you prepay',
  contentNew: 'save over 16%',
  subContent: '$16.67/month',
  price: annuallyPlanPriceForAvod,
  period: 'annually',
  periodNew: 'per year',
  features: [
    'Hit shows and blockbuster movies',
    'Binge-watch with no ads',
    'Download & Go selected content',
    'Watch on two screens at once'
  ],
  staticDetail: {
    name: 'Annual Plan',
    value: `$${annuallyPlanPriceForAvod}/year`
  },
  offerDetail: {
    name: 'ANNUAL PLAN',
    desc: `$${annuallyPlanPriceForAvod} for 12 months, prepay and save 16%`
  },
  voucherDetail: {
    header: 'This Annual Plan voucher has been added to your account',
    subHeader: 'What’s included in the Annual Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Annual Plan will cost $${annuallyPlanPriceForAvod} per year after your voucher expires`
  },
  paymentCopy: 'Your Annual Plan will start immediately. Your Annual Plan will renew automatically after 12 months unless you cancel before your renewal date. If you decide to cancel you can do so anytime from My Account. If you cancel your Annual plan any time before your plan renews you will not be eligible for a refund of the amount paid but you can keep watching until your Annual plan ends.',
  legacyPaymentCopy: 'Your Annual plan will renew automatically after 12 months unless you cancel before your renewal date. If you decide to cancel you can do so anytime from My Account. If you cancel your Annual plan any time before your plan renews you will not be eligible for a refund of the amount paid but you can keep watching until your Annual plan ends.',
  upgradeCopy: 'This part payment is for the use of your new plan from today, until your plan renewal date. On your next renewal date, you\'ll be charged the full price for your upgraded plan.'
}

export const tvodDetails = {
  type: planType.TVOD,
  title: 'rent movies',
  content: 'Pay as you go movies',
  price: '0.00',
  features: [
    'No subscription required',
    'Pay as you go'
  ],
  rentalLink: {
    text: 'Browse available movie rentals here',
    link: '/rental'
  }
}

export const tvodDetailsForNewPrice = {
  type: planType.TVOD,
  title: 'Just After A Movie?',
  subTitle: 'No subscription required. Pay as you go.',
  content: 'Movie Rental prices vary from $7.99 for New Release movies and $5.99 for Library movies',
  buttonText: 'Rent Movies Now',
  rentalLink: {
    text: 'Browse available movie rentals here',
    link: '/rental'
  }
}

export const paymentCopy = {
  standard:
    'Please provide your credit card details below to complete your account set up. You can cancel any time.',
  basic:
    'Please provide your credit card details below to complete your account set up. You can cancel any time.',
  tvod: 'No upfront charges. No monthly subscription. Pay as you go',
  annual:
    'Please provide your credit card details below to complete your account set up.'
}

export const serverErrors = {
  VOUCHER_EXPIRED: 'Sorry, the voucher you have used has expired',
  VOUCHER_INVALID: 'Sorry, the voucher you have used is not valid',
  VOUCHER_REDEEMED: 'Sorry, this voucher has already been redeemed',
  DEFAULT:
    'We had an issue while trying to process your request, please try again or contact one of our support representatives if the problem persists'
}

export const creditCardStatus = {
  ACTIVE: 'ACTIVE'
}
export const signupImage =
  'https://lightbox-prod.imgix.net/images/static/neon-signup-devices.png?q=75'

export const featuresHaveTooltip = [
  {
    name: 'SD*',
    description: 'Viewing may automatically upscale to HD on Android TVs.'
  },
  {
    name: '540p*',
    description: 'Viewing may automatically upscale to HD on Android TVs.'
  },
  {
    name: 'Video Ads + Pause Ads*',
    description: 'Pause ads will not show on mobile platforms.'
  },
  {
    name: 'Pause Ads only*',
    description: 'Pause ads will not show on mobile platforms.'
  }
]

export const priceChangeAlertMessage = {
  myAccount: `Neon's price is changing. A monthly Standard Plan will be $19.99 per month or Annual Plan will be $199.99 for 12 months
  if your next billing date listed below is on or after 11 January`,
  standard: `<b>Neon's price is changing</b>. Your new Standard plan will be $19.99 per month if your plan renews on or after 11 January.`,
  annual: `<b>Neon's price is changing</b>. Your new Annual plan will be $199.99 for 12 months if your plan renews on or after 11 January.`
}

export const basicPlanDetailsWithDiscount = {
  originalPrice: basicPlanPriceForAvod,
  priceBeforeDot: '9',
  priceAfterDot: '.99',
  period: 'per month for the first 3 months',
  endDate: 'Offer ends 14 January 2025.',
  label: 'LIMITED OFFER',
  termsAndConditionsLink: '/basic-plan-offer',
  welcomeBackHeader: 'Welcome back! Get NEON Basic plan for only $9.99 per month for 3 months',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 14 January 2025.'
}

export const standardPlanDetailsWithDiscount = {
  originalPrice: standardPlanPriceForAvod,
  priceBeforeDot: '9',
  priceAfterDot: '.99',
  period: 'per month for the first 3 months',
  endDate: 'Offer ends 16 November 2024.',
  label: 'LIMITED OFFER',
  termsAndConditionsLink: '/standard-plan-offer',
  welcomeBackHeader: 'Welcome back! Get 50% off for 3 months, only $9.99 per month on a Standard plan',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 16 November 2024.'
}

export const annualPlanDetailsWithDiscount = {
  originalPrice: annuallyPlanPriceForAvod,
  priceBeforeDot: '99',
  priceAfterDot: '.99',
  period: 'per month for the first 3 months',
  endDate: 'Offer ends 16 November 2024.',
  label: 'LIMITED OFFER',
  termsAndConditionsLink: '/annual-plan-offer',
  welcomeBackHeader: 'Welcome back! Get 50% off for 3 months, only $99.99 per month on an Annual plan',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 16 November 2024.'
}
